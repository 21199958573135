<template>
    <div class="textaPendulum">
        <div class="ml-20 mt-20">4号回拨(025)</div>
        <div class="mt-60">
            <el-form ref="upform" size="small" :model="upform" label-width="120px" :rules="rules">
                <el-form-item label="接口账户：" prop="interfaceId" v-if="userType == 'system'">
                    <div class="form-item-w-400">
                        <el-select
                            v-model="upform.interfaceId"
                            placeholder="请选择接口账户"
                            style="width: 100%"
                            clearable
                            filterable
                        >
                            <el-option
                            v-for="(item, index) in interface_id"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="小号：" prop="telX" v-if="userType != 'system'">
                    <div class="form-item-w-400">
                        <el-select
                            v-model="upform.telX"
                            placeholder="请选择接口账户"
                            style="width: 100%"
                            clearable
                            filterable
                        >
                            <el-option
                            v-for="(item, index) in numberList"
                            :key="index"
                            :label="item.number"
                            :value="item.number"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model="upform.name" class="form-item-w-400" placeholder="请输入姓名" clearable />
                </el-form-item>
                <el-form-item label="身份证：" prop="idCard">
                    <el-input v-model="upform.idCard" class="form-item-w-400" placeholder="请输入身份证" clearable />
                </el-form-item>
                <el-form-item label="手机号：" prop="number">
                    <el-input v-model="upform.number" class="form-item-w-400" placeholder="请输入手机号" clearable />
                </el-form-item>
                <el-form-item label="验证码：">
                    <div class="form-item-w-400 flex-nowrap-space-between">
                        <el-input v-model="upform.code" class="form-item-w-200" placeholder="请输入验证码" clearable />
                        <el-button size="small" @click="getCode('upform')">获取验证码</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <el-button size="small" plain class="yes-748bfd-bg" @click="upFormData('upform')">提交报备</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import ctcc from '@/api/open/ctcc'
import interfacets from "@/api/open/privacy/interface";
import number from "@/api/open/privacy/number";
export default {
    components: {

    },
    data () {
        const checkIdNum = (rule, value, callback) => {
            let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
            if (!value) {
                return callback(new Error('证件号码不能为空'))
            } else if (!reg.test(value)) {
                return callback(new Error('证件号码不正确'))
            } else {
                callback()
            }
        }
        return {
            upform:{
                idCard:'',
                name:'',
                number:'',
                code:'',
                telX:''
            },//加白表单
            interface_id:[],
            numberList:[],
            userType:'system',
            rules:{
                name:[
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 8,
                        message: '姓名长度不超过 2 至 8 个字符',
                        trigger: 'blur',
                    },
                ],
                interfaceId:[
                    {
                    required: true,
                    message: '请选择接口账户',
                    trigger: 'change',
                    },
                ],
                idCard:[
                    { required: true, validator: checkIdNum, trigger: 'blur' },
                ],
                telX:[
                    {
                        required: true,
                        message: '请选择接口账户',
                        trigger: 'change',
                    },
                ],
                number:[
                    { required: true, message: '请输入手机号', trigger: 'change' },
                    { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', trigger: 'change' }
                ],
            }
        }
    },
    created() {
        
    },
    watch: {

    },
    mounted() {
        this.userType = this.$store.state.userType
        if(this.userType == 'system'){
            this.getChangeValues()
        }else{
            this.getnumberList()
        }
    },
    methods: {
        getChangeValues(){
            interfacets.getInterface({}).then((res) => {
                this.interface_id = res.data
            });
        },
        getnumberList(){
            number.getHaoWaiNumberlist({}).then((res) => {
                this.numberList = res.data
                console.log(this.numberList)
            });
        },
        upFormData(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    ctcc.ctccWhiteSms({
                        ...this.upform,
                        type:1
                    }).then(res => {
                        if(res.code == 200){
                            this.$notify({
                                title: '成功',
                                message: '报备成功！',
                                type: 'success',
                            })
                        }
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        getCode(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    ctcc.ctccWhiteSms({
                        ...this.upform,
                        type:0
                    }).then(res => {
                        if(res.code == 200){
                            this.$notify({
                                title: '成功',
                                message: '验证码发送成功！',
                                type: 'success',
                            })
                        }
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .form-item-w-400{
        width: 400px;
    }
    .form-item-w-200{
        width: 200px;
    }
</style>